<!-- 订单详情 -->

<template>
    <div class="order-detail-dialog">
        <w-dialog ref="dialogRef" title="会员订单" width="60%" top="5vh" :hideFooter="true">
            <common-table ref="detailsTable" tableHeight="500" :ispaging="false"
                :apiName="Order.orderRelated" :extraParame="extraParame" :columns="detailsColumns"
                @onOrderDetailBtn="onOrderDetailBtn">
            </common-table>
        </w-dialog>
    </div>
</template>

<script>
    import { ref, nextTick, } from "vue";
    import { Order } from "@/plugins/api.js";
    // import { ElMessage, } from "element-plus";

    export default {
        emits: ["onOrderDetailBtn",],
        components: {},
        setup(props, { emit }) {
            const dialogRef = ref(null);  // 弹框对象
            const detailsTable = ref(null);  // 详情表格对象
            /** 表格配置数据 */
            const detailsColumns = ref([{
            		prop: "orw_no",
            		label: "订单编号",
            		color: "--text-color",
            		showTooltip: true,
            		minWidth: 150
            	},
            	{
            		prop: "orw_total_money",
            		label: "订单金额",
            		color: "--text-color",
            		showTooltip: true,
            	},
            	{
            		prop: "orw_elec_money",
            		label: "电费",
            		color: "--text-color",
            		showTooltip: true,
            	},
            	{
            		prop: "orw_sevice_money",
            		label: "服务费",
            		color: "--text-color",
            		showTooltip: true,
            	},
            	{
            		prop: "or_status_title",
            		label: "订单状态",
            		type: "option",
            		metaData: ["进行中", "待支付", "已支付"],
            		colorData: ["var(--text-blue-color)", "var(--error-color)", "var(--theme-color)", ],
            		showTooltip: true,
            	},
            	{
            		prop: "orw_status_title",
            		label: "充电状态",
            		type: "option",
            		metaData: ["充电中", "已完成"],
            		colorData: ["var(--text-blue-color)", "var(--theme-color)", ],
            		showTooltip: true,
            	},
            	{
            		prop: "s_name",
            		label: "充电站",
            		color: "--text-color",
            		showTooltip: true,
            	},
            	{
            		prop: "e_name",
            		label: "充电桩",
            		color: "--text-color",
            		showTooltip: true,
            	},
            	{
            		prop: "c_name",
            		label: "充电枪",
            		color: "--text-color",
            		showTooltip: true,
            	},
            	{
            		prop: "orw_start_time",
            		label: "充电开始",
            		color: "--text-color",
            		showTooltip: true,
            	},
            	{
            		prop: "orw_end_time",
            		label: "充电结束",
            		color: "--text-color",
            		showTooltip: true,
            	},
            	{
            		prop: "charg_duration",
            		label: "充电时长",
            		color: "--text-color",
            		showTooltip: true,
            	},
            	{
            		prop: "orw_total_power",
            		label: "电量",
            		color: "--text-color",
            		showTooltip: true,
            	},
            	{
            		prop: "total_discount",
            		label: "优惠减免合计",
            		color: "--text-color",
            		showTooltip: true,
            	},
            	{
            		prop: "payed_money",
            		label: "已付金额",
            		color: "--text-color",
            		showTooltip: true,
            	},
            	{
            		prop: "pay_way",
            		label: "收款方式",
            		color: "--text-color",
            		showTooltip: true,
            	},
            	{
            		prop: "orw_system_check_time",
            		label: "收款时间",
            		color: "--text-color",
            		showTooltip: true,
            	}
            ]);
            /**
             * 
             * 打开弹框
             * 
             * */
			 const extraParame = ref({})
            const openDialog = (data) => {
				extraParame.value = data
                dialogRef.value.show();
                nextTick(() => {
                    detailsTable.value.tableLoad();
                })
            };
            /**
             * 
             * 关闭弹框
             * 
             * */
            const closeDialog = () => {
                dialogRef.value.close();
            };
            /**
             * 
             * 查看详情
             * 
             * */
            const onOrderDetailBtn = (row) => {
                emit("onOrderDetailBtn", row);
            }
            return {
                openDialog,
                dialogRef,
                closeDialog,
                onOrderDetailBtn,
                detailsTable,
                extraParame,
                detailsColumns,
				Order
            };
        },
    };
</script>

<style lang="scss" scoped>

</style>